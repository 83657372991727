import { Typography, Box } from "@mui/material";
import { useState } from "react";
import {
  SettingSidebar,
  SettingSection,
} from "components/settings/SettingSidebar";
import Account from "components/settings/Account";
import Subscription from "components/settings/Subscription";

const SettingsView: React.FC = () => {
  const [activeSection, setActiveSection] = useState<SettingSection>(
    SettingSection.Account
  );

  const renderContent = () => {
    switch (activeSection) {
      case SettingSection.Account:
        return <Account />;
      case SettingSection.Subscription:
        return <Subscription />;
      case SettingSection.Security:
        return (
          <Box>
            <Typography variant="h5">Sécurité</Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box display="flex">
      <SettingSidebar
        onSelect={setActiveSection}
        activeSection={activeSection}
      />
      <Box flex={1} p={3}>
        {renderContent()}
      </Box>
    </Box>
  );
};

export default SettingsView;
