import ScanReportSidebar from "components/check-access/sidebar/ScanReportSidebar";
import ScanReportRetribution from "components/check-access/scanReportRetribution/ScanReportRetribution";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";

const CA_THEME_PALETTE = responsiveFontSizes(
  createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#488570",
        light: "#5da88d",
        dark: "#346353",
      },
      secondary: {
        main: "#0b1f19",
        light: "#0b1f19",
        dark: "#0b1f19",
      },
      grey: {
        100: "#c7ccdb",
      },
      warning: {
        main: "#FFAF3F",
      },
      error: {
        main: "#FF3535",
      },
      success: {
        main: "#51FF63",
      },
      info: {
        main: "#6294FF",
      },
      common: {
        black: "#424b54",
        white: "#ffffff",
      },
    },
  })
);

export const CA_THEME = createTheme(CA_THEME_PALETTE, {
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: CA_THEME_PALETTE.palette.common.black,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: CA_THEME_PALETTE.palette.grey[700],
        },
      },
    },
  },
});

const CheckAccessHomeView: React.FC = () => {
  return (
    <ThemeProvider theme={CA_THEME}>
      <div className="flex h-full w-full justify-between">
        <ScanReportSidebar />
        <ScanReportRetribution />
      </div>
    </ThemeProvider>
  );
};

export default CheckAccessHomeView;
