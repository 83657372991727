import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import ProjectDashboardAppBar from "components/ProjectDashboardAppBar";
import { Sidebar } from "components/sidebar/Sidebar";

const SIDEBAR_WIDTH = 280;

interface DashboardRootViewProps {
  sidebarElements: React.ReactNode;
}

const DashboardRootView: React.FC<DashboardRootViewProps> = ({
  sidebarElements,
}) => {
  return (
    <Box className="flex w-full h-full">
      <CssBaseline />
      <Sidebar width={SIDEBAR_WIDTH}>{sidebarElements}</Sidebar>
      <ProjectDashboardAppBar sidebarWidth={SIDEBAR_WIDTH} />
      <Box component="main" className="flex flex-col flex-grow h-screen">
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardRootView;
