import { ReactComponent as CompliantIcon } from "../assets/icons/criteria-states/compliant.svg";
import { ReactComponent as NonCompliantIcon } from "../assets/icons/criteria-states/not-compliant.svg";
import { ReactComponent as NotApplicableIcon } from "../assets/icons/criteria-states/not-applicable.svg";
import { ReactComponent as NeedInterventionIcon } from "../assets/icons/criteria-states/need-intervention.svg";
import { CriteriaState } from "@ca/report";

export type CriteriaStateTypes =
  | CriteriaState.compliant
  | "nonCompliant"
  | CriteriaState.notApplicable
  | "needIntervention";

export type PaletteTheme = "error" | "success" | "warning" | "info";

export interface CriteriaStateDetails {
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  displayTitle: string;
  paletteTheme: PaletteTheme;
}

export const CRITERIA_STATE_DETAILS: Record<
  CriteriaStateTypes,
  CriteriaStateDetails
> = {
  compliant: {
    Icon: CompliantIcon,
    displayTitle: "Conformes",
    paletteTheme: "success",
  },
  nonCompliant: {
    Icon: NonCompliantIcon,
    displayTitle: "Non conformes",
    paletteTheme: "error",
  },
  notApplicable: {
    Icon: NotApplicableIcon,
    displayTitle: "Non applicables",
    paletteTheme: "info",
  },
  needIntervention: {
    Icon: NeedInterventionIcon,
    displayTitle: "Besoin d'intervention",
    paletteTheme: "warning",
  },
};
