import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CheckAccessFrameContext } from "./CheckAccessFrame/CheckAccessFrame";
import { evaluateElementPath } from "utils/xPathUtils";

export interface XPathFinderContextProps {
  addXPath: (xPath: string) => void;
  removeXPath: (xPath: string) => void;

  getElementByXPath: (xPath: string) => HTMLElement | null;

  scrollPictoIntoView: (key: string, xPath: string) => void;
}

const XPathFinderContext = createContext<XPathFinderContextProps | null>(null);

const XPathFinderProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { frame, shadowContainer } = useContext(CheckAccessFrameContext)!;

  const xPathCounter = useRef<Map<string, number>>(new Map());
  const [elements, setElements] = useState<Map<string, HTMLElement | null>>(
    new Map()
  );

  const elementsRef = useRef(elements);
  useEffect(() => {
    elementsRef.current = elements;
  }, [elements]);

  const addXPath = useCallback(
    (xPath: string) => {
      setElements((prev) => {
        xPathCounter.current.set(
          xPath,
          (xPathCounter.current.get(xPath) || 0) + 1
        );
        if (prev.has(xPath)) return prev;

        const element = frame?.contentDocument
          ? evaluateElementPath(frame?.contentDocument, xPath)
          : null;

        return new Map(prev.set(xPath, element));
      });
    },
    [frame?.contentDocument]
  );

  const removeXPath = useCallback((xPath: string) => {
    setElements((prev) => {
      const actualCount = xPathCounter.current.get(xPath);

      if (actualCount === undefined) {
        console.warn("XPath not found in counter", xPath);
        return prev;
      }

      if (actualCount > 1) {
        xPathCounter.current.set(xPath, actualCount - 1);
        return prev;
      }

      xPathCounter.current.delete(xPath);
      prev.delete(xPath);

      return new Map(prev);
    });
  }, []);

  const getElementByXPath = useCallback(
    (xPath: string) => {
      return elements.get(xPath) || null;
    },
    [elements]
  );

  const retrievePicto = useCallback(
    (key: string, xPath: string) => {
      const el = shadowContainer.current?.querySelector(
        `[data-ca-retrival-id="${key}-${xPath}"]`
      );
      if (!el || el.nodeType !== Node.ELEMENT_NODE) {
        return null;
      }

      return el as HTMLElement;
    },
    [shadowContainer]
  );

  const scrollPictoIntoView = useCallback(
    (key: string, xPath: string) => {
      const element = getElementByXPath(xPath);
      if (!element) return;

      element.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });

      let retry = 0;
      let interval = setInterval(() => {
        const picto = retrievePicto(key, xPath);

        if (!picto && retry > 100) {
          console.warn(
            `Picto ${key}-${xPath} not found after ${retry} retries`
          );
          clearInterval(interval);
          return;
        }

        if (!picto) {
          retry++;
          return;
        }

        picto.click();
        clearInterval(interval);
      }, 50);
    },
    [getElementByXPath, retrievePicto]
  );

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (!frame?.contentDocument) return;
      const contentDocument = frame.contentDocument;

      const findedPaths = [
        ...elementsRef.current
          .entries()
          .filter(([_, element]) => !element)
          .map(
            ([xPath, _]) =>
              [xPath, evaluateElementPath(contentDocument, xPath)] as const
          )
          .filter(([_, element]) => element !== null),
      ];

      const elementsToRemove = [
        ...elementsRef.current
          .entries()
          .filter(
            ([_, element]) =>
              element !== null && !contentDocument.body.contains(element)
          ),
      ];

      if (findedPaths.length === 0 && elementsToRemove.length === 0) return;

      setElements((prev) => {
        const newElements = new Map(prev);

        findedPaths.forEach(([xPath, element]) =>
          newElements.set(xPath, element)
        );
        elementsToRemove.forEach(([xPath, _]) => newElements.set(xPath, null));

        return newElements;
      });
    });

    observer.observe(frame?.contentDocument || document, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [frame?.contentDocument]);

  return (
    <XPathFinderContext.Provider
      value={{
        addXPath,
        removeXPath,
        getElementByXPath,

        scrollPictoIntoView,
      }}
    >
      {children}
    </XPathFinderContext.Provider>
  );
};

const useXPathFinder = (xPath: string) => {
  const { addXPath, removeXPath, getElementByXPath } =
    useContext(XPathFinderContext)!;

  useEffect(() => {
    addXPath(xPath);

    return () => removeXPath(xPath);
  }, [xPath, addXPath, removeXPath]);

  return getElementByXPath(xPath);
};

export { XPathFinderContext, XPathFinderProvider, useXPathFinder };
