import Box from "@mui/material/Box/Box";
import React, { useContext, useMemo } from "react";
import SidebarTopicHeader from "./SidebarTopicHeader";
import AccordionPictoLink from "../accordion/AccordionPictoLink";
import { ReactComponent as FrameIcon } from "../../../../assets/icons/icon-frame.svg";
import {
  FramesCriteria,
  InterventionRequirement,
  number_to_frame_criteria,
} from "@ca/report";
import { ReportContext, TargetedNonCompliantErrors } from "providers/Report";

export interface FrameSidebarTopicDetailsProps {}

const FramesSidebarTopicDetails: React.FC<
  FrameSidebarTopicDetailsProps
> = () => {
  const { pageReport } = useContext(ReportContext)!;
  const { nonCompliantCriterias, needInterventionsCriterias } =
    useContext(ReportContext)!;

  const frameErrors: Record<FramesCriteria, TargetedNonCompliantErrors[]> =
    useMemo(() => {
      return Object.fromEntries(
        nonCompliantCriterias
          .filter((criteria) => criteria.topic === "frames")
          .map((criteria) => [
            number_to_frame_criteria(criteria.number),
            criteria.state.nonCompliant,
          ])
          .concat(
            needInterventionsCriterias
              .filter((criteria) => criteria.topic === "frames")
              .map((criteria) => [
                number_to_frame_criteria(criteria.number),
                criteria.state.needIntervention,
              ])
          )
      );
    }, [needInterventionsCriterias, nonCompliantCriterias]);

  const framesNeededInterventions = useMemo(
    () =>
      pageReport?.neededInterventions.filter(
        (inter) => inter.type === "frameTitle"
      ) as InterventionRequirement.frameTitle[],
    [pageReport?.neededInterventions]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <SidebarTopicHeader topic="frames" />
      <Box
        sx={{
          margin: "25px 8px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box>
          <AccordionPictoLink
            title="2.1 Cadre: présence du titre"
            IconError={(props) => <FrameIcon {...props} stroke="white" />}
            xPathsError={
              frameErrors.titleImplemented?.map((error) => error.x_path) || []
            }
            retrivalKey="FrameTitle"
            topic="frames"
            criteriaNumber={1}
          />
          <AccordionPictoLink
            title="2.2 Cadre: pertinence du titre"
            IconError={(props) => <FrameIcon {...props} stroke="white" />}
            IconWarning={(props) => <FrameIcon {...props} stroke="white" />}
            xPathsWarning={
              framesNeededInterventions?.map((inter) => inter.xPath) || []
            }
            xPathsError={
              frameErrors.revelantTitle?.map((error) => error.x_path) || []
            }
            retrivalKey="FrameTitle"
            topic="frames"
            criteriaNumber={2}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FramesSidebarTopicDetails;
