import React from "react";
import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import OrganizationsDashboardView from "./pages/organizations/OrganizationsDashboardView";
import CheckAccessHomeView from "./pages/project/check-access/CheckAccessHomeView";
import ProjectDashboardHomeView from "./pages/project/ProjectDashboardHomeView";
import OrganizationDashboardHomeView from "./pages/organization/OrganizationDashboardHomeView";
import AdaptifyAnalyticsGeneralView from "./pages/project/adaptify/AdaptifyAnalyticsGeneralView";
import AdaptifyAnalyticsAdaptifyUsage from "./pages/project/adaptify/AdaptifyAnalyticsAdaptifyUsage";
import { AnalyticsMetricsProvider } from "./providers/AnalyticsMetricsProvider";
import CrispChat from "components/CrispChat";
import ErrorFallback from "components/ErrorFallback";
import NotFoundPage from "components/NotFoundPage";
import { InterventionProvider } from "providers/InterventionsProvider";
import { ReportProvider } from "providers/Report";
import AssociationInvitationView from "pages/AssociationInvitationView";
import DashboardRootView from "pages/DashboardRootView";
import ProjectSidebar from "components/sidebar/ProjectSidebar";
import MainSidebar from "components/sidebar/MainSidebar";
import OrganizationSidebar from "components/sidebar/OrganizationSidebar";
import { CARetributionActiveTopicsProvider } from "providers/CARetributionActiveTopics";
import { CheckAccessFrameProvider } from "providers/CheckAccessFrame/CheckAccessFrame";
import { ScanerProvider } from "providers/ScanerProvider";
import SettingsView from "pages/settings/SettingsView";
import { XPathFinderProvider } from "providers/XPathFinder";
import { CurrentFrameProjectPageProvider } from "providers/CurrentFrameProjectPage";

export const PROJECTS_URL = "/dashboard/projects";
export const ORGANIZATIONS_URL = "/dashboard/organizations";

export const CHECK_ACCESS_URL = "ca";
export const ADAPTIFY_ANALYTICS_URL = "analytics";
export const ADAPTIFY_ANALYTICS_USAGE_URL = "analyticsusage";

export const createProjectRoute = (projectId: string, nested?: string) =>
  `${PROJECTS_URL}/${projectId}/${nested || ""}`;
export const createOrganizationRoute = (
  organizationId: string,
  nested?: string
) => `${ORGANIZATIONS_URL}/${organizationId}/${nested || ""}`;

const adaptifyAnalyticsRoutes: RouteObject[] = [
  {
    path: ADAPTIFY_ANALYTICS_URL,
    index: true,
    element: (
      <AnalyticsMetricsProvider>
        <AdaptifyAnalyticsGeneralView />
      </AnalyticsMetricsProvider>
    ),
  },
];

const adaptifyAnalyticsUsageRoutes: RouteObject[] = [
  {
    path: ADAPTIFY_ANALYTICS_USAGE_URL,
    index: true,
    element: (
      <AnalyticsMetricsProvider>
        <AdaptifyAnalyticsAdaptifyUsage />
      </AnalyticsMetricsProvider>
    ),
  },
];

const projectRoutes: RouteObject[] = [
  {
    path: `${PROJECTS_URL}/:projectId`,
    element: <DashboardRootView sidebarElements={<ProjectSidebar />} />,
    errorElement: <ErrorFallback />,
    children: [
      {
        index: true,
        element: <ProjectDashboardHomeView />,
      },
      ...adaptifyAnalyticsRoutes,
      ...adaptifyAnalyticsUsageRoutes,
    ],
  },
  {
    path: `${PROJECTS_URL}/:projectId/${CHECK_ACCESS_URL}`,
    errorElement: <ErrorFallback />,
    element: (
      <CARetributionActiveTopicsProvider>
        <InterventionProvider>
          <CheckAccessFrameProvider>
            <CurrentFrameProjectPageProvider>
              <ScanerProvider>
                <ReportProvider>
                  <XPathFinderProvider>
                    <CheckAccessHomeView />
                  </XPathFinderProvider>
                </ReportProvider>
              </ScanerProvider>
            </CurrentFrameProjectPageProvider>
          </CheckAccessFrameProvider>
        </InterventionProvider>
      </CARetributionActiveTopicsProvider>
    ),
  },
];

const organizationRoutes: RouteObject[] = [
  {
    path: ORGANIZATIONS_URL,
    element: <DashboardRootView sidebarElements={<MainSidebar />} />,
    errorElement: <ErrorFallback />,
    children: [
      {
        index: true,
        element: <OrganizationsDashboardView />,
      },
    ],
  },
  {
    path: `${ORGANIZATIONS_URL}/:organizationId`,
    element: <DashboardRootView sidebarElements={<OrganizationSidebar />} />,
    children: [
      {
        index: true,
        element: <OrganizationDashboardHomeView />,
      },
    ],
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={ORGANIZATIONS_URL} replace />,
    errorElement: <ErrorFallback />,
  },
  {
    path: "/association/invite/:inviteToken",
    element: <AssociationInvitationView />,
  },
  {
    path: "/settings",
    element: <DashboardRootView sidebarElements={<MainSidebar />} />,
    children: [
      {
        index: true,
        element: <SettingsView />,
      },
    ],
  },
  ...projectRoutes,
  ...organizationRoutes,
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

const Router: React.FC = () => {
  return (
    <>
      <RouterProvider router={router} fallbackElement={<NotFoundPage />} />
      <CrispChat />
    </>
  );
};

export default Router;
