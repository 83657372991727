import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SessionsByOsMetric from "../../../components/metrics/SessionsByOsMetric";
import GeneralDashboardAnalyticsHeader from "../../../components/metrics/GeneralDashboardAnalyticsHeader";
import SessionsByDevicesMetric from "../../../components/metrics/SessionsByDevicesMetric";
import SessionsPerPage from "../../../components/metrics/SessionsPerPageMetric";
import PagesPerEntranceMetric from "../../../components/metrics/PagesPerEntranceMetric";
import SessionsByCountryMetric from "../../../components/metrics/SessionsByCountryMetric";
import AnalyticsDatePicker from "components/metrics/AnalyticsDatePicker";

const AdaptifyAnalyticsGeneralView: React.FC = () => {
  return (
    <Box className="flex flex-col flex-grow p-6 pt-9 mb-6">
      <AnalyticsDatePicker />
      <Box>
        <GeneralDashboardAnalyticsHeader />
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(6, 1fr)"
        gridTemplateRows={"1fr 0.75fr"}
        className="flex-grow"
        sx={{
          pt: "1.25rem",
          gridColumnGap: "1.25rem",
          gridRowGap: "1.25rem",
        }}
      >
        <Box gridArea="1 / 1 / 1 / 4">
          <SessionsByOsMetric />
        </Box>
        <Box gridArea="1 / 4 / 1 / 7">
          <SessionsByCountryMetric />
        </Box>

        <Box gridArea="2 / 1 / 2 / 3">
          <SessionsByDevicesMetric />
        </Box>
        <Box gridArea="2 / 3 / 2 / 5">
          <SessionsPerPage />
        </Box>
        <Box gridArea="2 / 5 / 2 / 7">
          <PagesPerEntranceMetric />
        </Box>
      </Box>
      <Grid container spacing={3} sx={{ flexGrow: 1 }}></Grid>
    </Box>
  );
};

export default AdaptifyAnalyticsGeneralView;
