import { useEffect, useMemo } from "react";
import { MetricFunctionName, MetricResult } from "../supabaseClient";
import useAnalyticsMetricsProvider from "./useAnalyticsMetricsProvider";

const useMetric = <T extends MetricFunctionName>(
  metric: T
): MetricResult<T> | null => {
  const { getMetricResult, fetchMetric } = useAnalyticsMetricsProvider();
  const metricResult = useMemo(
    () => getMetricResult(metric),
    [getMetricResult, metric]
  );

  useEffect(() => {
    if (!metricResult) {
      fetchMetric(metric);
    }
  }, [fetchMetric, metric, metricResult]);

  return metricResult;
};

export default useMetric;
