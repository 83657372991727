import {
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  MetricFunctionName,
  MetricResult,
  TimeInterval,
} from "../supabaseClient";
import useInterval from "../hooks/useInterval";
import useCurrentDomainId from "../hooks/useCurrentDomainId";
import useMetricsCache from "../hooks/useMetricsCache";
import dayjs, { Dayjs } from "dayjs";

const serializeTimeInterval = (interval: TimeInterval) => {
  const strInterval: TimeInterval<string> = {
    dtStart: interval.dtStart.toISOString(),
    dtEnd: interval.dtEnd.toISOString(),
  };

  return btoa(JSON.stringify(strInterval));
};

const parseTimeInterval = (baseTimeInterval: string): TimeInterval | null => {
  try {
    const interval = JSON.parse(atob(baseTimeInterval)) as TimeInterval<string>;

    return {
      dtStart: dayjs(interval.dtStart),
      dtEnd: dayjs(interval.dtEnd),
    };
  } catch (e) {
    console.warn("Error while parsing time interval", e);
    return null;
  }
};

const retrieveTimeInterval = (
  currentDate: Dayjs,
  baseTimeInterval: string | null
): TimeInterval => {
  const parsedTimeInterval =
    baseTimeInterval && parseTimeInterval(baseTimeInterval);

  if (parsedTimeInterval) return parsedTimeInterval;

  return {
    dtStart: currentDate.subtract(1, "month"),
    dtEnd: currentDate,
  };
};

type AnalyticsMetricssContextProps = {
  timeInterval: TimeInterval;
  setTimeInterval: (timeInterval: SetStateAction<TimeInterval>) => void;
  fetchMetric: (metric: MetricFunctionName) => Promise<void>;
  getMetricResult: <T extends MetricFunctionName>(
    metric: T
  ) => MetricResult<T> | null;
};

const AnalyticsMetricsContext =
  createContext<AnalyticsMetricssContextProps | null>(null);

const AnalyticsMetricsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const domainId = useCurrentDomainId();

  const [currentDate, setCurrentDate] = useState<Dayjs>(() => dayjs());
  const [timeInterval, setTimeInterval] = useState<TimeInterval>(() =>
    retrieveTimeInterval(currentDate, searchParams.get("timeInterval"))
  );

  const { getMetricResult, fetchMetric } = useMetricsCache(
    timeInterval,
    domainId
  );

  useInterval(() => setCurrentDate(dayjs()), 10 * 60 * 1000);

  useEffect(() => {
    setSearchParams({ timeInterval: serializeTimeInterval(timeInterval) });
  }, [timeInterval, setSearchParams]);

  if (!domainId) {
    // TODO: traiter le cas où le domaine n'existe pas
    return <div>Loading domain...</div>;
  }

  return (
    <AnalyticsMetricsContext.Provider
      value={{
        timeInterval,
        setTimeInterval,
        getMetricResult,
        fetchMetric,
      }}
    >
      {children}
    </AnalyticsMetricsContext.Provider>
  );
};

export { AnalyticsMetricsContext, AnalyticsMetricsProvider };
