import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box/Box";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ORGANIZATIONS_URL } from "router";
import { supabase } from "supabaseClient";

const AssociationInvitationView: React.FC = () => {
  const inviteToken = useParams().inviteToken;
  const [isSucess, setIsSucess] = useState<boolean | null>(null);

  if (!inviteToken) {
    throw new Error("inviteToken is not defined");
  }

  useEffect(() => {
    supabase
      .rpc("use_association_invite", { invite_key: inviteToken })
      .then(({ error }) => {
        setIsSucess(!error);
      });
  }, [inviteToken]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      {isSucess === null ? (
        <p>Chargement de l'invitation...</p>
      ) : isSucess ? (
        <Box textAlign="center">
          <Typography variant="h3">Bienvenue !</Typography>
          <Typography sx={{ margin: 2 }}>
            Votre offre pour le widget Adaptify à bien été pris en compte ! Vous
            vouvez vous dirigez vers le dashboard
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href={ORGANIZATIONS_URL}
            size="large"
          >
            Dashboard
          </Button>
        </Box>
      ) : (
        <Box textAlign="center" className="flex gap-3">
          <Typography variant="h3">Échec de l'Invitation</Typography>
          <Typography sx={{ margin: 2 }}>
            Désolé, il y a eu un problème lors de l'acceptation de l'invitation.
            Peut-être que l'invitation à déjà été utilisée.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default AssociationInvitationView;
