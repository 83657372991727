import { Topic } from "@ca/report";
import { ALL_TOPICS } from "definitions/topic_details";
import { createContext, ReactNode, useCallback, useState } from "react";

export interface CARetributionActiveTopicsContextProps {
  activeOnlyOneTopic: (topic: Topic) => void;
  activeAll: () => void;
  isTopicActivated: (topic: Topic) => boolean;
  isTheOnlyTopicActivated: (topic: Topic) => boolean;
}

const CARetributionActiveTopicsContext =
  createContext<CARetributionActiveTopicsContextProps | null>(null);

const CARetributionActiveTopicsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [activeTopics, setActiveTopics] = useState<Set<Topic>>(
    new Set(ALL_TOPICS)
  );

  const activeOnlyOneTopic = useCallback(
    (topic: Topic) => {
      // This is to prevent useless re-rendering
      if (activeTopics.size === 1 && activeTopics.has(topic)) return;

      setActiveTopics(new Set([topic]));
    },
    [activeTopics]
  );

  const activeAll = useCallback(() => {
    if (activeTopics.size === ALL_TOPICS.length) return;

    setActiveTopics(new Set(ALL_TOPICS));
  }, [activeTopics.size]);

  const isTopicActivated = useCallback(
    (topic: Topic) => activeTopics.has(topic),
    [activeTopics]
  );
  const isTheOnlyTopicActivated = useCallback(
    (topic: Topic) => activeTopics.size === 1 && activeTopics.has(topic),
    [activeTopics]
  );

  return (
    <CARetributionActiveTopicsContext.Provider
      value={{
        activeOnlyOneTopic,
        activeAll,
        isTopicActivated,
        isTheOnlyTopicActivated,
      }}
    >
      {children}
    </CARetributionActiveTopicsContext.Provider>
  );
};

export { CARetributionActiveTopicsContext, CARetributionActiveTopicsProvider };
