import {
  CheckAccessFrameContext,
  DeviceMode,
} from "providers/CheckAccessFrame/CheckAccessFrame";
import React, { useContext, useEffect, useState } from "react";

const DEVICE_OFFSET: Record<DeviceMode, DOMRect> = {
  [DeviceMode.Laptop]: new DOMRect(0, 0, 0, 0),
  [DeviceMode.Tablet]: new DOMRect(28, 37, 56, 71),
  [DeviceMode.Phone]: new DOMRect(10, 12, 21, 25),
};

const DeviceMockup: React.FC = () => {
  const { frame, deviceMode } = useContext(CheckAccessFrameContext)!;
  const [rect, setRect] = useState<DOMRect | null>(null);

  useEffect(() => {
    if (!frame) {
      return;
    }

    const handleResize = () => {
      const rect = frame.getBoundingClientRect();
      setRect(rect);
    };

    handleResize();

    frame.contentWindow?.addEventListener("resize", handleResize);
    frame.contentWindow?.addEventListener("resize", handleResize);

    return () => {
      frame.contentWindow?.removeEventListener("resize", handleResize);
    };
  }, [frame, deviceMode]);

  if (!frame || !rect || deviceMode === DeviceMode.Laptop) {
    return null;
  }

  const deviceOffset = DEVICE_OFFSET[deviceMode] || new DOMRect(0, 0, 0, 0);

  return (
    <img
      src={`/device-mockup/${deviceMode}.png`}
      alt={`${deviceMode} mockup`}
      style={{
        position: "absolute",
        left: `${rect.left - deviceOffset.x}px`,
        top: `${rect.top - deviceOffset.y}px`,
        width: `${rect.width + deviceOffset.width}px`,
        height: `${rect.height + deviceOffset.height}px`,
        pointerEvents: "none",
        zIndex: 1,
      }}
    />
  );
};

export default DeviceMockup;
