import IconButton from "@mui/material/IconButton/IconButton";
import Paper from "@mui/material/Paper/Paper";
import React, { useCallback, useContext, useState } from "react";
import InputBase from "@mui/material/InputBase/InputBase";
import useCurrentProject from "hooks/useCurrentProject";
import Chip from "@mui/material/Chip/Chip";
import Avatar from "@mui/material/Avatar/Avatar";
import SearchIcon from "@mui/icons-material/Search";
import { ClickAwayListener } from "@mui/material";
import { CurrentFrameProjectPageContext } from "providers/CurrentFrameProjectPage";

export interface PathSearchBarProps {}

const PathSearchBar: React.FC<PathSearchBarProps> = () => {
  const project = useCurrentProject();

  const { currentProjectPage, updateCurrentPage } = useContext(
    CurrentFrameProjectPageContext
  )!;
  const [pagePath, setPagePath] = useState<string>(currentProjectPage.path);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (evt: React.SyntheticEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      setIsLoading(true);
      await updateCurrentPage(pagePath);
      setIsLoading(false);
    },
    [pagePath, updateCurrentPage]
  );

  return (
    <ClickAwayListener onClickAway={() => setPagePath(currentProjectPage.path)}>
      <Paper
        component="form"
        onSubmit={handleSubmit}
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: 400,
          backgroundColor: "white",
          borderRadius: "28px",
        }}
      >
        <Chip
          color="secondary"
          avatar={
            <Avatar
              alt="Project favicon"
              src={`${project.http_scheme}://${project.domain}/favicon.ico`}
            />
          }
          label={project.domain}
        />
        <InputBase
          sx={{ ml: 1, flex: 1, color: "secondary.main" }}
          placeholder="Chemin de la page"
          inputProps={{ "aria-label": "change current page" }}
          onChange={(evt) => setPagePath(evt.target.value)}
          value={pagePath}
        />
        <IconButton
          type="submit"
          sx={{ p: "10px" }}
          aria-label="search"
          color={"secondary"}
          loading={isLoading}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    </ClickAwayListener>
  );
};

export default PathSearchBar;
