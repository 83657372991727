import Box from "@mui/material/Box/Box";
import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import RetributionIcon from "components/check-access/scanReportRetribution/RetributionIcon";
import CriteriaAccordion from "./CriteriaAccordion";
import { XPathFinderContext } from "providers/XPathFinder";
import { CRITERIA_STATE_DETAILS } from "definitions/criteria_state_details";
import { getCriteriaState, ReportContext } from "providers/Report";
import { Topic } from "@ca/report";

export type AccordionPictoLinkProps = {
  title: string;
  retrivalKey: string;
  topic: Topic;
  criteriaNumber: number;
} & WarningPath &
  ErrorPath;

type WarningPath =
  | {
      xPathsWarning: string[];
      IconWarning: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
    }
  | {
      xPathsWarning?: undefined;
      IconWarning?: undefined;
    };

type ErrorPath =
  | {
      xPathsError: string[];
      IconError: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
    }
  | {
      xPathsError?: undefined;
      IconError?: undefined;
    };

const AccordionPictoLink: React.FC<AccordionPictoLinkProps> = ({
  title,
  IconError,
  IconWarning,
  xPathsWarning,
  xPathsError,
  retrivalKey,
  topic,
  criteriaNumber,
}) => {
  const { pageReport } = useContext(ReportContext)!;
  const { palette } = useTheme();
  const { scrollPictoIntoView } = useContext(XPathFinderContext)!;

  const criteria = pageReport?.criterias.find(
    (criteria) => criteria.topic === topic && criteria.number === criteriaNumber
  );

  if (!criteria) return null;

  const criteriaState = getCriteriaState(criteria);
  const details = CRITERIA_STATE_DETAILS[criteriaState];

  return (
    <CriteriaAccordion
      title={title}
      Icon={<details.Icon stroke={palette[details.paletteTheme].main} />}
      type={details.paletteTheme}
    >
      <Box sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
        {xPathsWarning &&
          xPathsWarning.map((path, index) => (
            <RetributionIcon
              onClick={() => scrollPictoIntoView(retrivalKey, path)}
              Icon={IconWarning}
              key={index}
              color={palette["warning"].main}
            />
          ))}
        {xPathsError &&
          xPathsError.map((path, index) => (
            <RetributionIcon
              onClick={() => scrollPictoIntoView(retrivalKey, path)}
              Icon={IconError}
              key={index}
              color={palette["error"].main}
            />
          ))}
      </Box>
    </CriteriaAccordion>
  );
};

export default AccordionPictoLink;
