import { useCallback, useContext } from "react";
import { Enums, supabase } from "../supabaseClient";
import { logError } from "utils/SentryUtils";
import { OrganizationsContext } from "providers/OrganizationsProvider";
import { NewProjectPayload } from "./useCreateOrganization";

export type CreateNewProjectPayload = NewProjectPayload & {
  organizationId: string;
};

const useCreateProject = () => {
  const { fetchOrganizations } = useContext(OrganizationsContext)!;

  const createProject = useCallback(
    async (projectPayload: CreateNewProjectPayload) => {
      const httpScheme = projectPayload.url.protocol
        ? projectPayload.url.protocol.slice(0, -1)
        : "https";

      const { error: insertProjectError } = await supabase
        .from("projects")
        .insert({
          domain: projectPayload.url.hostname,
          http_scheme: httpScheme as Enums<"http_scheme">,
          organization_id: projectPayload.organizationId,
          name: projectPayload.name,
        });

      if (insertProjectError) {
        logError("enable to insert Project:", insertProjectError);
        return;
      }

      await fetchOrganizations();
    },
    [fetchOrganizations]
  );

  return createProject;
};

export default useCreateProject;
