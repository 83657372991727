import useMetric from "../../hooks/useMetric";
import MetricCard from "./MetricCard";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import TableView from "./TableView";

const SessionsPerPage = () => {
  const metric = useMetric("entrances_by_page");

  return (
    <MetricCard title="Sessions par page" Icon={<WebAssetIcon />}>
      {metric && metric.length > 0 && (
        <TableView
          className="max-h-[150px]"
          row={metric.map((el) => ({
            page_url: el.page_url,
            num: el.new_sessions,
          }))}
        />
      )}
      {metric && metric.length === 0 && <div>No data</div>}
    </MetricCard>
  );
};

export default SessionsPerPage;
