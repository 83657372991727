import Box from "@mui/material/Box/Box";
import React, { useCallback, useEffect, useState } from "react";
import Step from "@mui/material/Step/Step";
import StepContent from "@mui/material/StepContent/StepContent";
import FormControl from "@mui/material/FormControl/FormControl";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import StepButton from "@mui/material/StepButton/StepButton";
import Stepper from "@mui/material/Stepper/Stepper";
import { Intervention } from "@ca/report";
import { DisplayedNodeType } from "./useInformationStructuringFrameRetribution";
import { PaletteTheme } from "definitions/criteria_state_details";
import { useTheme } from "@mui/material";
import { NullableProps } from "utils/tsUtils";
import styled from "@mui/material/styles/styled";

const StyledRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

export interface InterventionStepProps {
  index: number;
  paletteTheme: PaletteTheme;

  label: string;
  onLabelClick: () => void;

  content?: String;

  value: boolean | null;
  onChange: (value: boolean) => void;
}

const InterventionStep: React.FC<InterventionStepProps> = ({
  paletteTheme,
  index,
  label,
  onLabelClick,
  value,
  onChange,
}) => {
  const { palette } = useTheme();

  return (
    <Step
      key={label}
      index={index}
      completed={value !== null}
      sx={{
        "& .MuiStepLabel-iconContainer svg": {
          color: palette.grey[500],
        },
        "& .MuiStepLabel-iconContainer .Mui-completed": {
          color: palette[paletteTheme].main,
        },
        "& .MuiStepLabel-iconContainer .Mui-active": {
          color: palette[paletteTheme].main,
        },
      }}
    >
      <StepButton
        onClick={onLabelClick}
        sx={{
          "& .MuiStepLabel-label": {
            color: palette.grey[600],
          },
          "& .MuiStepLabel-label.Mui-active": {
            color: "black",
          },
          "& .MuiStepLabel-label.Mui-completed": {
            color: "black",
          },
        }}
      >
        {label}
      </StepButton>
      <StepContent>
        <Box>
          <FormControl>
            <RadioGroup
              value={value}
              onChange={(e) => {
                onChange(e.target.value === "true");
              }}
            >
              <FormControlLabel
                value={true}
                control={<StyledRadio color={paletteTheme} />}
                label="Oui"
              />
              <FormControlLabel
                value={false}
                control={<StyledRadio color={paletteTheme} />}
                color={paletteTheme}
                label="Non"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </StepContent>
    </Step>
  );
};

const useInformationStructuringFormHelper = <T extends Intervention>(
  defaultIntervention: NullableProps<T>,
  onSubmit: (intervention: T) => void,
  baseIntervention?: T
) => {
  const [intervention, setIntervention] = useState<NullableProps<T>>(
    baseIntervention || defaultIntervention
  );

  const setInterventionProps = useCallback(
    (prop: keyof T, value: boolean) => {
      const updatedIntervention = {
        ...intervention,
        [prop]: value,
      };

      setIntervention(updatedIntervention);

      if (Object.values(updatedIntervention).some((val) => val === null))
        return;

      onSubmit(updatedIntervention as T);
    },
    [intervention, onSubmit]
  );

  return {
    intervention,
    setInterventionProps,
  };
};

type StructuringCriteriaFormProps<T extends Intervention> = {
  onSubmit: (intervention: T) => void;
  paletteTheme: PaletteTheme;
  baseIntervention?: T;
  isActive: boolean;
  onChangeDisplayedNodeType: (displayedNodeType: DisplayedNodeType) => void;
};

const TitlesCriteriaForm: React.FC<
  StructuringCriteriaFormProps<Intervention.informationStructuringTitles>
> = ({
  onSubmit,
  onChangeDisplayedNodeType,
  paletteTheme,
  baseIntervention,
  isActive,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const { intervention, setInterventionProps } =
    useInformationStructuringFormHelper<Intervention.informationStructuringTitles>(
      {
        type: "informationStructuringTitles",
        hasGoodHierarchy: null,
        allTitlesAreRelevant: null,
        hasMissingStructuredTitles: null,
      },
      onSubmit,
      baseIntervention
    );

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    onChangeDisplayedNodeType(DisplayedNodeType.Titles);
  }, [onChangeDisplayedNodeType]);

  const updateInterventionProps = (
    prop: keyof Intervention.informationStructuringTitles,
    value: boolean
  ) => {
    setInterventionProps(prop, value);
    handleNext();
  };

  useEffect(() => {
    if (isActive) onChangeDisplayedNodeType(DisplayedNodeType.Titles);
  }, [isActive, onChangeDisplayedNodeType]);

  return (
    <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
      <InterventionStep
        paletteTheme={paletteTheme}
        index={0}
        label="La hiérarchie des titres est-elle correcte ?"
        onLabelClick={() => setActiveStep(0)}
        value={intervention.hasGoodHierarchy}
        onChange={(val) => updateInterventionProps("hasGoodHierarchy", val)}
      />

      <InterventionStep
        paletteTheme={paletteTheme}
        label="Le contenu des titres est-il pertinent ?"
        index={1}
        onLabelClick={() => setActiveStep(1)}
        value={intervention.allTitlesAreRelevant}
        onChange={(val) => updateInterventionProps("allTitlesAreRelevant", val)}
      />

      <InterventionStep
        paletteTheme={paletteTheme}
        index={2}
        label="Manque t'il des titres?"
        onLabelClick={() => setActiveStep(2)}
        value={intervention.hasMissingStructuredTitles}
        onChange={(val) =>
          setInterventionProps("hasMissingStructuredTitles", val)
        }
      />
    </Stepper>
  );
};

const GeneralStructureCriteriaForm: React.FC<
  StructuringCriteriaFormProps<Intervention.informationStructuringGeneral>
> = ({
  onSubmit,
  onChangeDisplayedNodeType,
  paletteTheme,
  baseIntervention,
  isActive,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const { intervention, setInterventionProps } =
    useInformationStructuringFormHelper<Intervention.informationStructuringGeneral>(
      {
        type: "informationStructuringGeneral",
        hasGoodHeader: null,
        hasGoodNav: null,
        hasGoodMain: null,
        hasGoodFooter: null,
      },
      onSubmit,
      baseIntervention
    );

  const setDisplayedNodeType = useCallback(
    (step: number) => {
      switch (step) {
        case 0:
          onChangeDisplayedNodeType(DisplayedNodeType.Header);
          break;
        case 1:
          onChangeDisplayedNodeType(DisplayedNodeType.Nav);
          break;
        case 2:
          onChangeDisplayedNodeType(DisplayedNodeType.Main);
          break;
        case 3:
          onChangeDisplayedNodeType(DisplayedNodeType.Footer);
          break;
        default:
          console.warn("Unhandled step");
          break;
      }
    },
    [onChangeDisplayedNodeType]
  );

  const handleNext = () => {
    const newStep = activeStep + 1;
    setActiveStep(newStep);
  };

  const updateInterventionForm = (
    prop: keyof Intervention.informationStructuringGeneral,
    value: boolean
  ) => {
    setInterventionProps(prop, value);
    handleNext();
  };

  useEffect(() => {
    if (isActive) setDisplayedNodeType(activeStep);
  }, [activeStep, isActive, setDisplayedNodeType]);

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      nonLinear
      onFocus={() => setDisplayedNodeType(activeStep)}
    >
      <InterventionStep
        paletteTheme={paletteTheme}
        index={0}
        label="Le header est-t'il bien représenté ?"
        onLabelClick={() => setActiveStep(0)}
        value={intervention.hasGoodHeader}
        onChange={(val) => updateInterventionForm("hasGoodHeader", val)}
      />

      <InterventionStep
        paletteTheme={paletteTheme}
        index={1}
        label="Les navbars sont bien représentés ?"
        onLabelClick={() => setActiveStep(1)}
        value={intervention.hasGoodNav}
        onChange={(val) => updateInterventionForm("hasGoodNav", val)}
      />

      <InterventionStep
        paletteTheme={paletteTheme}
        index={2}
        label="Le corps main est bien représenté ?"
        onLabelClick={() => setActiveStep(2)}
        value={intervention.hasGoodMain}
        onChange={(val) => updateInterventionForm("hasGoodMain", val)}
      />

      <InterventionStep
        paletteTheme={paletteTheme}
        index={3}
        label="Le footer est bien représenté ?"
        onLabelClick={() => setActiveStep(3)}
        value={intervention.hasGoodFooter}
        onChange={(val) => updateInterventionForm("hasGoodFooter", val)}
      />
    </Stepper>
  );
};

const ListsCriteriaForm: React.FC<
  StructuringCriteriaFormProps<Intervention.informationStructuringLists>
> = ({ onSubmit, onChangeDisplayedNodeType, paletteTheme, isActive }) => {
  const [hasMissingLists, setHasMissingLists] = useState<boolean | null>(null);

  useEffect(() => {
    if (isActive) onChangeDisplayedNodeType(DisplayedNodeType.Lists);
  }, [isActive, onChangeDisplayedNodeType]);

  return (
    <FormControl>
      Manque t'il des lists ?
      <RadioGroup
        value={hasMissingLists}
        onChange={(e) => {
          const val = e.target.value === "true";
          setHasMissingLists(val);
          onSubmit({
            type: "informationStructuringLists",
            hasMissingLists: val,
          });
        }}
      >
        <FormControlLabel
          value={true}
          control={<StyledRadio color={paletteTheme} />}
          label="Oui"
        />
        <FormControlLabel
          value={false}
          control={<StyledRadio color={paletteTheme} />}
          color={paletteTheme}
          label="Non"
        />
      </RadioGroup>
    </FormControl>
  );
};

export { TitlesCriteriaForm, GeneralStructureCriteriaForm, ListsCriteriaForm };
