import { useEffect } from "react";

const useInterval = (callback: () => void, duration: number) => {
  useEffect(() => {
    const intervalID = setInterval(callback, duration);
    return () => clearInterval(intervalID);
  }, [callback, duration]);
};

export default useInterval;
