import { redirect, useParams } from "react-router-dom";
import { ORGANIZATIONS_URL } from "../router";
import useOrganization from "./useOrganization";

const useCurrentOrganization = () => {
  const organizationId = useParams().organizationId!;

  if (!organizationId) {
    redirect(ORGANIZATIONS_URL);
    throw new Error("organizationId is not defined");
  }

  return useOrganization(organizationId);
};

export default useCurrentOrganization;
