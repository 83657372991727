import Box from "@mui/material/Box/Box";
import React, { CSSProperties } from "react";

// We cannot use tailwind inside the iframe, so we need to use custom styles, an improvement would be to inject a css files into the iframe
const ICON_CUSTOM_STYLE: CSSProperties = {
  width: "32px",
  height: "32px",
  display: "inline-block",
  borderRadius: "5px",
  position: "relative",
  verticalAlign: "middle",
};

export type RetributionIconProps = {
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  color: string;
} & React.HTMLAttributes<HTMLDivElement>;

const RetributionIcon = React.forwardRef<HTMLDivElement, RetributionIconProps>(
  ({ Icon, color, ...props }, ref) => {
    return (
      <Box
        ref={ref}
        style={{
          ...ICON_CUSTOM_STYLE,
          backgroundColor: color,
          ...(props.onClick && { cursor: "pointer" }),
        }}
        {...props}
      >
        <Icon width={32} height={32} />
      </Box>
    );
  }
);

export default RetributionIcon;
