import Typography from "@mui/material/Typography/Typography";
import {
  DEFAULT_DOCUMENTATION_LINK,
  DOCUMENTATION_LINKS,
  tryIntoSupportedTech,
} from "hooks/useTechDetection";

interface DocumentationDetectionProps {
  detectedCms: string | null;
}

const DocumentationDetectionStep: React.FC<DocumentationDetectionProps> = ({
  detectedCms,
}) => {
  const supportedTech = detectedCms ? tryIntoSupportedTech(detectedCms) : null;
  const documentationLink =
    supportedTech != null
      ? DOCUMENTATION_LINKS[supportedTech]
      : DEFAULT_DOCUMENTATION_LINK;

  let explanationText;
  if (supportedTech) {
    explanationText = (
      <p>
        Site <strong>{detectedCms}</strong> détecté! Voici la documentation
        nécessaire à l'installation.
      </p>
    );
  } else if (detectedCms) {
    explanationText = (
      <p>
        Site <strong>{detectedCms}</strong> détecté! Nous n'avons pas encore de
        documentation spécifique pour votre CMS voici donc la documentation
        d'installation générale.
      </p>
    );
  } else {
    explanationText = (
      <p>Voici la documentation nécessaire à l'installation.</p>
    );
  }

  return (
    <Typography className="flex flex-col w-fit">
      {explanationText}
      <a
        className="cursor-pointer font-bold m-2 border border-black rounded-lg p-1.5 bg-green-950 text-white hover:bg-green-200 hover:text-black transition flex flex-row"
        rel="noreferrer"
        target="_blank"
        href={documentationLink}
      >
        Documentation
      </a>
    </Typography>
  );
};

export default DocumentationDetectionStep;
