import { useContext } from "react";
import { OrganizationsContext } from "../providers/OrganizationsProvider";

const useOrganizations = () => {
  const orgContext = useContext(OrganizationsContext);

  if (!orgContext) {
    throw new Error(
      "useOrganizations must be used within a OrganizationsProvider"
    );
  }

  return orgContext.organizations;
};

export default useOrganizations;
