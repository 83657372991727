/* eslint-disable import/no-webpack-loader-syntax */
import {
  createContext,
  ReactEventHandler,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from "react";
import useCookiePopupRemover from "./useCookiePopupRemover";
import useDeviceTouchSimulator from "./useDeviceTouchSimulator";
import { EmotionCache } from "@emotion/react";
import createCache from "@emotion/cache";

export enum DeviceMode {
  Laptop = "laptop",
  Tablet = "tablet",
  Phone = "phone",
}

export interface CheckAccessFrameContextProps {
  frame: HTMLIFrameElement | null;

  deviceMode: DeviceMode;
  changeDeviceMode: React.Dispatch<React.SetStateAction<DeviceMode>>;

  shadowContainer: React.RefObject<Element | null>;
  shadowContainerStyleCache: EmotionCache;

  contentDocument: Document | null;
  handleFrameLoad: ReactEventHandler<HTMLIFrameElement>;
}

const CheckAccessFrameContext =
  createContext<CheckAccessFrameContextProps | null>(null);

const CheckAccessFrameProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [frame, setFrame] = useState<HTMLIFrameElement | null>(null);
  const [deviceMode, setDeviceMode] = useState<DeviceMode>(DeviceMode.Laptop);

  useDeviceTouchSimulator(deviceMode !== DeviceMode.Laptop, frame);

  const shadowContainer = useRef<Element | null>(null);
  const [shadowContainerStyleCache, setShadowContainerStyleCache] =
    useState<EmotionCache>(() =>
      createCache({
        key: "css",
        prepend: true,
      })
    );

  useCookiePopupRemover(frame?.contentDocument || null);

  const handleFrameLoad: ReactEventHandler<HTMLIFrameElement> = useCallback(
    (evt) => {
      setFrame(evt.currentTarget);

      if (!evt.currentTarget.contentDocument) return;

      const shadowEl = evt.currentTarget.contentDocument.createElement("div");
      const shadowRoot = shadowEl.attachShadow({ mode: "open" });

      evt.currentTarget.contentDocument.body.appendChild(shadowEl);

      const newShadowContainer =
        evt.currentTarget.contentDocument.createElement("div");

      shadowRoot.appendChild(newShadowContainer);

      shadowContainer.current = newShadowContainer;

      setShadowContainerStyleCache(
        createCache({
          key: "css",
          container: newShadowContainer,
          prepend: true,
        })
      );
    },
    []
  );

  return (
    <CheckAccessFrameContext.Provider
      value={{
        frame,

        deviceMode,
        changeDeviceMode: setDeviceMode,

        shadowContainer,
        shadowContainerStyleCache,

        handleFrameLoad,
        contentDocument: frame?.contentDocument || null,
      }}
    >
      {children}
    </CheckAccessFrameContext.Provider>
  );
};

export { CheckAccessFrameContext, CheckAccessFrameProvider };
