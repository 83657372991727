import { CheckAccessFrameContext } from "providers/CheckAccessFrame/CheckAccessFrame";
import { ReportContext } from "providers/Report";
import React, { useCallback, useContext, useMemo } from "react";
import RetributionButton from "../RetributionButton";
import { ReactComponent as ContrastIcon } from "../../../../assets/icons/icon-contrast.svg";
import { TextScan } from "@ca/report";
import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";
import RetributionButtonPopper from "../RetributionButtonPopper";
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import useScaner from "hooks/useScaner";
import { InterventionsContext } from "providers/InterventionsProvider";

export interface ColorsRetributionProps {}

const ColorsRetribution: React.FC<ColorsRetributionProps> = () => {
  const { contentDocument } = useContext(CheckAccessFrameContext)!;
  const { reportCurrentPage } = useContext(ReportContext)!;
  const { currentPageScan } = useScaner();
  const { findErrorByPathAndType } = useContext(ReportContext)!;
  const { replaceTargetedIntervention } = useContext(InterventionsContext)!;

  const updateContrastIntervention = useCallback(
    (textScan: TextScan, isContrastGood: boolean) => {
      if (!currentPageScan) return;

      replaceTargetedIntervention(
        {
          type: "textContrast",
          x_path: textScan.xPath,
          has_good_contrast: isContrastGood,
        },
        currentPageScan.page_id
      );

      reportCurrentPage();
    },
    [replaceTargetedIntervention, currentPageScan, reportCurrentPage]
  );

  const constrastErrorsElements = useMemo(() => {
    if (!contentDocument || !currentPageScan) return [];

    return currentPageScan.scan.texts.textScans
      .map((text) => {
        const isContrastError = findErrorByPathAndType(
          text.xPath,
          "badTextContrast"
        );

        let what_should_contrast_be;

        if (text.textSize < 18.5 || (text.textSize < 24.0 && !text.isBold)) {
          what_should_contrast_be = "4.5:1";
        } else {
          what_should_contrast_be = "3:1";
        }

        const stateType = isContrastError ? "error" : "info";
        const defaultValue = isContrastError ? "bad" : "good";

        return (
          <RetributionButtonPopper
            key={text.xPath}
            xPath={text.xPath}
            placement="left"
            offset={[0, 4]}
          >
            <Box>
              <RetributionButton
                retrivalId={`textContrast-${text.xPath}`}
                Icon={(props) => (
                  <ContrastIcon {...props} fill="white" stroke="white" />
                )}
                xPath={text.xPath}
                tooltipProps={{
                  title: `Contrast (${text.contrast.toFixed(2)}:1)`,
                  content: (
                    <Box>
                      <Typography
                        sx={{
                          lineHeight: "1",
                        }}
                        marginBottom={1}
                      >
                        <strong>text gras:</strong>
                        {text.isBold ? " oui" : " non"}
                      </Typography>

                      <Typography
                        sx={{
                          lineHeight: "1",
                        }}
                        marginBottom={1}
                      >
                        <strong>taille du text:</strong>{" "}
                        {text.textSize.toFixed(1)}px
                      </Typography>

                      <Typography
                        sx={{
                          lineHeight: "1",
                        }}
                        marginBottom={1}
                      >
                        <strong>Contrast: </strong>
                        {isContrastError
                          ? "trop bas entre le texte et le background"
                          : "bon"}
                      </Typography>

                      <Typography
                        sx={{
                          lineHeight: "1",
                        }}
                        marginBottom={1}
                      >
                        Le contrast devrais être de{" "}
                        <strong>{what_should_contrast_be}</strong> minimum
                      </Typography>

                      <Divider sx={{ mb: 1 }} />

                      <FormControl>
                        <FormLabel>Le contrast est t'il bon?</FormLabel>
                        <RadioGroup
                          row
                          name={`textContrastRadioGroup-${text.xPath}`}
                          value={defaultValue}
                        >
                          <FormControlLabel
                            value="good"
                            control={
                              <Radio
                                name={`textContrastRadioGroup-${text.xPath}-Relevant`}
                                color={stateType}
                                onClick={() =>
                                  updateContrastIntervention(text, true)
                                }
                              />
                            }
                            label="oui"
                          />
                          <FormControlLabel
                            value="bad"
                            control={
                              <Radio
                                name={`textContrastRadioGroup-${text.xPath}-NotRelevant`}
                                color={stateType}
                                onClick={() =>
                                  updateContrastIntervention(text, false)
                                }
                              />
                            }
                            label="Non"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  ),
                  referenceLink:
                    "https://accessibilite.numerique.gouv.fr/methode/criteres-et-tests/#3.2",
                }}
                type={stateType}
              />
            </Box>
          </RetributionButtonPopper>
        );
      })
      .filter((element) => element !== null);
  }, [
    contentDocument,
    currentPageScan,
    updateContrastIntervention,
    findErrorByPathAndType,
  ]);

  return <React.Fragment>{constrastErrorsElements}</React.Fragment>;
};

export default ColorsRetribution;
