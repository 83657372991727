import { Topic } from "@ca/report";
import Box from "@mui/material/Box/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useXScale } from "@mui/x-charts";
import { ScaleBand } from "@mui/x-charts-vendor/d3-scale";
import { BarChart } from "@mui/x-charts/BarChart/BarChart";
import { BarSeriesType } from "@mui/x-charts/models";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import { ReportContext } from "providers/Report";
import React, { useContext, useMemo } from "react";
import Stack from "@mui/material/Stack/Stack";
import Divider from "@mui/material/Divider/Divider";
import { Typography } from "@mui/material";
import {
  CRITERIA_STATE_DETAILS,
  CriteriaStateTypes,
} from "definitions/criteria_state_details";

type ChartSerieType = MakeOptional<BarSeriesType, "type" | "id"> & {
  id: CriteriaStateTypes;
};

const TopicScoreBarChartLabels = ({
  series,
}: {
  series: MakeOptional<BarSeriesType, "type">[];
}) => {
  const xAxisScale = useXScale() as ScaleBand<number>;
  const total = series.reduce((acc, serie) => acc + serie.data?.[0]!, 0);

  let accumulator = 0;
  return (
    <React.Fragment>
      {series
        .map((serie, index) => {
          const currentVal = serie.data?.[0]!;

          const x = xAxisScale((accumulator + (currentVal + accumulator)) / 2)!;

          accumulator += currentVal;
          if (currentVal === 0 || total / currentVal < 0.2) {
            return null;
          }

          const Icon =
            CRITERIA_STATE_DETAILS[serie.id as CriteriaStateTypes].Icon;

          return (
            <g
              key={index}
              style={{
                transform: `translate3d(${x - 18}px, 7px, 0)`,
              }}
            >
              <Icon stroke={"black"} height={36} width={36} />
            </g>
          );
        })
        .filter(Boolean)}
    </React.Fragment>
  );
};

export interface TopicScoreBarChartProps {
  topics: Topic[];
}

const TopicScoreBarChart: React.FC<TopicScoreBarChartProps> = ({ topics }) => {
  const { palette } = useTheme();
  const {
    compliantCriterias,
    nonCompliantCriterias,
    notApplicableCriterias,
    needInterventionsCriterias,
  } = useContext(ReportContext)!;

  const criterias = useMemo(() => {
    return {
      compliants: compliantCriterias.filter((criteria) =>
        topics.includes(criteria.topic)
      ),
      nonCompliants: nonCompliantCriterias.filter((criteria) =>
        topics.includes(criteria.topic)
      ),
      notApplicables: notApplicableCriterias.filter((criteria) =>
        topics.includes(criteria.topic)
      ),
      needInterventions: needInterventionsCriterias.filter((criteria) =>
        topics.includes(criteria.topic)
      ),
    };
  }, [
    compliantCriterias,
    needInterventionsCriterias,
    nonCompliantCriterias,
    notApplicableCriterias,
    topics,
  ]);

  const series: ChartSerieType[] = useMemo(() => {
    return [
      {
        id: "compliant",
        label: "Conformes",
        data: [criterias.compliants.length],
        stack: "A",
        color: palette.success.main,
      },
      {
        id: "nonCompliant",
        label: "Non conformes",
        data: [criterias.nonCompliants.length],
        stack: "A",
        color: palette.error.main,
      },
      {
        id: "notApplicable",
        label: "Non appliquable",
        data: [criterias.notApplicables.length],
        stack: "A",
        color: palette.info.main,
      },
      {
        id: "needIntervention",
        label: "Besoin d'intervention",
        data: [criterias.needInterventions.length],
        stack: "A",
        color: palette.warning.main,
      },
    ];
  }, [criterias, palette]);

  const renderCriteriaStateLegend = (criteriaState: CriteriaStateTypes) => {
    const { Icon, displayTitle, paletteTheme } =
      CRITERIA_STATE_DETAILS[criteriaState];

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Icon stroke={palette[paletteTheme].main} width="32px" height="32px" />
        <Typography sx={{ fontSize: "1.15rem" }}>{displayTitle}</Typography>
      </Box>
    );
  };

  return (
    <Box>
      <BarChart
        height={50}
        borderRadius={10}
        tooltip={{
          trigger: "item",
        }}
        series={series}
        layout="horizontal"
        margin={{ top: 0, left: 35, right: 35, bottom: 0 }}
        leftAxis={null}
        bottomAxis={null}
        sx={{
          "& clipPath > rect": {
            clipPath: "inset(0px round 10px 10px 10px 10px) !important",
          },
        }}
        slotProps={{
          legend: {
            hidden: true,
          },
        }}
      >
        <TopicScoreBarChartLabels series={series} />
      </BarChart>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: 2,
          color: "white",
        }}
      >
        <Stack>
          {renderCriteriaStateLegend("compliant")}
          {renderCriteriaStateLegend("nonCompliant")}
        </Stack>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ borderColor: "white" }}
        />
        <Stack>
          {renderCriteriaStateLegend("notApplicable")}
          {renderCriteriaStateLegend("needIntervention")}
        </Stack>
      </Box>
    </Box>
  );
};

export default TopicScoreBarChart;
