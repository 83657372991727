import { ClickAwayListener } from "@mui/material";
import Box from "@mui/material/Box/Box";
import Link from "@mui/material/Link/Link";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Typography from "@mui/material/Typography/Typography";
import { CheckAccessFrameContext } from "providers/CheckAccessFrame/CheckAccessFrame";
import React, { useContext } from "react";

export interface RetributionTooltipProps {
  open: boolean;

  title: string;
  content: string | React.ReactElement;
  referenceLink: string;
  color: string;
  onClickAway?: (event: MouseEvent | TouchEvent) => void;

  children: React.ReactElement<any, any>;
}

const RetributionTooltip: React.FC<RetributionTooltipProps> = ({
  open,
  title,
  content,
  referenceLink,
  color,
  onClickAway,
  children,
}) => {
  const { shadowContainer } = useContext(CheckAccessFrameContext)!;

  return (
    <Tooltip
      open={open}
      arrow
      PopperProps={{
        container: () => shadowContainer.current,
        placement: "bottom",
        modifiers: [
          {
            name: "flip",
            enabled: true,
          },
          {
            name: "computeStyles",
            options: {
              adaptive: false,
            },
          },
        ],
      }}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      slotProps={{
        tooltip: {
          sx: {
            boxShadow: 10,
            borderRadius: "16px",
            backgroundColor: "#f5f5f9",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: "350px",
            paddingBottom: "0px",
          },
        },
        popper: {
          sx: {
            zIndex: 1000000,
          },
        },
        arrow: {
          sx: {
            color: "#f5f5f9",
            fontSize: "20px",
          },
        },
      }}
      title={
        <ClickAwayListener onClickAway={(e) => onClickAway?.(e)}>
          <Box>
            <Box
              sx={{
                display: "flex",
                gap: "6px",
                padding: "8px",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="subtitle1"
                color={color}
                sx={{
                  lineHeight: "1",
                  mb: 1,
                }}
                fontWeight={"bold"}
                marginBottom={"5px"}
              >
                {title}
              </Typography>
              {content}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Link
                  href={referenceLink}
                  target="_blank"
                  sx={{
                    fontSize: "medium",
                    color: "grey",
                  }}
                >
                  Reference
                </Link>
              </Box>
            </Box>
            <Box
              sx={{
                height: "6px",
                backgroundColor: color,
                borderRadius: "0 0 4px 4px",
                mt: 1,
              }}
            />
          </Box>
        </ClickAwayListener>
      }
    >
      {children}
    </Tooltip>
  );
};

export default RetributionTooltip;
