import { displaySessionTime } from "../../utils/dayJsUtils";
import useMetric from "../../hooks/useMetric";
import MetricHeader from "./MetricHeader";

const GeneralDashboardAnalyticsHeader = () => {
  const newSessions = useMetric("number_of_new_sessions");
  const newUsers = useMetric("number_of_new_users");
  const bounceRate = useMetric("bounce_rate");
  const avgSessionTimeInSeconds = useMetric("avg_session_time_in_seconds");

  const avgSessionTimeDisplay =
    avgSessionTimeInSeconds !== null
      ? displaySessionTime(avgSessionTimeInSeconds)
      : null;
  const bounceRateDisplay =
    bounceRate !== null ? `${(bounceRate * 100).toFixed(1)}%` : null;

  const metrics = [
    {
      title: "Nouvelles sessions",
      value: newSessions?.toString(),
    },
    {
      title: "Nouveaux utilisateurs",
      value: newUsers?.toString(),
    },
    {
      title: "Taux de rebond",
      value: bounceRateDisplay,
    },
    {
      title: "Durée moyenne des sessions",
      value: avgSessionTimeDisplay,
    },
  ];

  return <MetricHeader metrics={metrics} />;
};

export default GeneralDashboardAnalyticsHeader;
