import { SvgIconComponent } from "@mui/icons-material";
import Warning from "@mui/icons-material/Warning";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography/Typography";
import { HTMLAttributeAnchorTarget } from "react";
import { NavLink } from "react-router-dom";

const activeStyling = {
  backgroundColor: "primary.light",
  color: "secondary.dark",

  "& .MuiListItemIcon-root": {
    color: "secondary.dark",
  },
};

interface SidebarButtonProps {
  Icon: SvgIconComponent;
  link: string;
  text: string;
  warning?: boolean;
  target?: HTMLAttributeAnchorTarget;
  isLast?: boolean;
}

const SidebarButton: React.FC<SidebarButtonProps> = ({
  Icon,
  link,
  text,
  warning,
  isLast = false,
}) => {
  const handleAlert = () => {
    alert("fonctionnalité bientôt disponible!");
  };

  return (
    <ListItem sx={{ p: 0, mt: isLast ? "auto" : 1, mb: isLast ? 3 : 0 }}>
      <ListItemButton
        sx={{
          p: 0,
          borderRadius: 3,

          "&:has(a.active)": activeStyling,
          "&:hover": activeStyling,
          "& .MuiListItemIcon-root": {
            color: "common.white",
          },
        }}
      >
        {warning ? (
          <div
            onClick={handleAlert}
            className={"flex gap-2 items-center p-2 w-full"}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Icon />
            </ListItemIcon>
            <Typography variant="subtitle1" noWrap>
              {text}
            </Typography>
            <Warning />
          </div>
        ) : (
          <NavLink to={link} className={"flex gap-2 items-center p-2 w-full"}>
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Icon />
            </ListItemIcon>
            <Typography variant="subtitle1" noWrap>
              {text}
            </Typography>
          </NavLink>
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default SidebarButton;
