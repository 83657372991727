import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { parsePageUrl } from "utils/parsURLUtils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transparent",
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  color: theme.palette.grey[700],
  border: 0,
  paddingTop: 3,
  paddingBottom: 3,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

interface MetricInfos {
  row: {
    page_url: string;
    num: number;
  }[];
  className?: string;
}

const TableView = (metric: MetricInfos) => (
  <TableContainer className={`h-full w-full ${metric.className}`}>
    <Table aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell align="left">Page</StyledTableCell>
          <StyledTableCell align="left">Nombre d'utilisation</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {metric.row.map((row) => (
          <StyledTableRow key={row.page_url}>
            <StyledTableCell component="th" scope="row">
              <Link
                to={row.page_url}
                target="_blank"
                className="text-[#0085FF] hover:text-blue-800 visited:text-purple-600"
              >
                {parsePageUrl(row.page_url)}
              </Link>
            </StyledTableCell>
            <StyledTableCell align="left">{row.num}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default TableView;
