import { PageScan } from "providers/ScanerProvider";
import { useContext, useMemo } from "react";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import React from "react";
import {
  CheckAccessFrameContext,
  DeviceMode,
} from "providers/CheckAccessFrame/CheckAccessFrame";
import { CARetributionActiveTopicsContext } from "providers/CARetributionActiveTopics";
import PicturesRetribution from "./FrameTopicRetribution/PicturesRetribution";
import ColorsRetribution from "./FrameTopicRetribution/ColorsRetribution";
import useScaner from "hooks/useScaner";
import MandatoryElementsRetribution from "./FrameTopicRetribution/MandatoryElementsRetribution";
import useCurrentProject from "hooks/useCurrentProject";
import FramesRetribution from "./FrameTopicRetribution/FramesRetribution";
import { SIDEBAR_MAX_WIDTH } from "../sidebar/ScanReportSidebar";
import DeviceMockup from "./DeviceMockup";
import TopToolbar, { TOP_TOOLBAR_HEIGHT } from "./TopToolbar/TopToolbar";
import Box from "@mui/material/Box/Box";
import { useCurrentProjectPage } from "providers/CurrentFrameProjectPage";

const API_URL = process.env.REACT_APP_CHECK_ACCESS_PROXY_URL!;

const computeScale = (
  deviceHeight: number,
  deviceWidth: number,
  heightMargin: number
) => {
  const height = window.innerHeight - heightMargin;
  const width = window.innerWidth;
  return Math.min(height / deviceHeight, width / deviceWidth);
};

const DEVICE_STYLE: Record<DeviceMode, () => React.CSSProperties> = {
  [DeviceMode.Laptop]: () => ({
    width: "100%",
    height: "100%",
  }),
  [DeviceMode.Tablet]: () => ({
    width: "1024px",
    height: "768px",
    transformOrigin: "center 0px",
    marginTop: "37px",
    transform: `scale(${computeScale(768, 1024, 160)})`,
  }),
  [DeviceMode.Phone]: () => {
    return {
      width: "360px",
      height: "780px",
      transformOrigin: "center 0px",
      marginTop: "20px",
      transform: `scale(${computeScale(780, 360, 100)})`,
    };
  },
};

const getScanUrl = (scan: PageScan): string => {
  return `${API_URL}/preview_scan_source?scanId=${scan.scan_id}&pageId=${scan.page_id}`;
};

interface ScanReportRetributionProps {}

const ScanReportRetribution: React.FC<ScanReportRetributionProps> = () => {
  const { isTopicActivated, isTheOnlyTopicActivated } = useContext(
    CARetributionActiveTopicsContext
  )!;

  const {
    contentDocument,
    handleFrameLoad,
    deviceMode,
    shadowContainerStyleCache,
  } = useContext(CheckAccessFrameContext)!;

  const currentProjectPage = useCurrentProjectPage();

  const currentProject = useCurrentProject();
  const { currentPageScan } = useScaner();

  const cache = useMemo(
    () =>
      createCache({
        key: "css",
        ...(contentDocument && {
          container: contentDocument.head,
        }),
        prepend: true,
      }),
    [contentDocument]
  );

  const frameUrl = currentPageScan
    ? getScanUrl(currentPageScan)
    : `${API_URL}${currentProjectPage.path}?ca_loaded_domain=${currentProject.domain}`;

  return (
    <CacheProvider value={cache}>
      <div className="flex flex-col w-full h-full">
        <TopToolbar />
        <Box
          sx={{
            backgroundColor: "primary.light",
            display: "flex",
            justifyContent: "center",
            maxHeight: `calc(100% - ${TOP_TOOLBAR_HEIGHT}px)`,
            maxWidth: `calc(100vw - ${SIDEBAR_MAX_WIDTH}px)`,
            flexGrow: 1,
          }}
        >
          <DeviceMockup />
          <iframe
            key={"website-preview-frame"}
            style={DEVICE_STYLE[deviceMode]?.()}
            title="website-preview"
            onLoad={handleFrameLoad}
            src={frameUrl}
          />
          <CacheProvider value={shadowContainerStyleCache}>
            {isTopicActivated("images") && <PicturesRetribution />}
            {isTopicActivated("frames") && <FramesRetribution />}
            {isTheOnlyTopicActivated("colors") && <ColorsRetribution />}
            {isTheOnlyTopicActivated("mandatoryElements") && (
              <MandatoryElementsRetribution />
            )}
          </CacheProvider>
        </Box>
      </div>
    </CacheProvider>
  );
};

export default ScanReportRetribution;
