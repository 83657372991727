import { Session } from "@supabase/supabase-js";
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { supabase, Tables } from "../supabaseClient";
import { logError } from "../utils/SentryUtils";
import useMixpanelTracking from "../hooks/useMixpanelTracking";
import mixpanel from "mixpanel-browser";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import * as fr from "../i18n/supabase_auth_fr.json";
import { tryGetSessionFormUrl } from "utils/supabase";
import * as Sentry from "@sentry/react";
import { Toolbar, Typography } from "@mui/material";

type AuthContextProps = {
  session: Session;
  userDetails: UserDetails | null;
  signOut: () => Promise<void>;
};

type AuthProviderProps = {
  children: ReactNode;
};

type UserDetails = Tables<"user_private_data">;

const fetchUserDetails = async (
  userId: string
): Promise<UserDetails | null> => {
  const { data, error } = await supabase
    .from("user_private_data")
    .select("*")
    .eq("user_id", userId);

  if (error) {
    logError("Error while fetching user details", error);
    return null;
  }

  if (data.length === 0) {
    return null;
  }

  if (data.length > 1) {
    logError(
      "Error while fetching user details",
      "Multiple users found, it should be unique"
    );
    return null;
  }

  return data[0];
};

export const AuthContext = createContext<AuthContextProps | null>(null);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [session, setSession] = useState<Session | null>(null);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const track = useMixpanelTracking();

  const handleSessionChange = useCallback((newSession: Session | null) => {
    setSession(newSession);
    mixpanel.identify(newSession?.user.id);
    Sentry.setUser({ email: newSession?.user.email, id: newSession?.user.id });
  }, []);

  useEffect(() => {
    tryGetSessionFormUrl()
      .then((authResponse) => {
        if (authResponse) {
          handleSessionChange(authResponse.data.session);
          return;
        }

        return supabase.auth.getSession().then(({ data: { session } }) => {
          handleSessionChange(session);
        });
      })
      .finally(() => setIsLoading(false));

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_, newSession) => {
      handleSessionChange(newSession);
    });

    return () => subscription.unsubscribe();
  }, [handleSessionChange]);

  useEffect(() => {
    if (!session || session.user.id === userDetails?.user_id) return;

    fetchUserDetails(session.user.id).then(setUserDetails);
  }, [session, userDetails]);

  const signOut = useCallback(async () => {
    const { error } = await supabase.auth.signOut();
    if (error) logError("Error while signing out", error);

    setSession(null);
    mixpanel.identify();

    track("Sign out");
  }, [track]);

  if (isLoading) return <div>Loading...</div>;

  if (!session)
    return (
      <div className="flex h-full justify-center items-center">
        <Toolbar></Toolbar>
        <div className="min-w-96">
          <Typography variant="h5" fontWeight={"bold"}>
            Inscrivez ou connectez-vous à Hub-Access !
          </Typography>
          <Auth
            supabaseClient={supabase}
            appearance={{ theme: ThemeSupa }}
            providers={["google", "github"]}
            view="sign_up"
            theme="light"
            localization={{
              variables: fr,
            }}
            redirectTo={window.location.href}
          />
        </div>
      </div>
    );

  return (
    <AuthContext.Provider
      value={{
        session,
        signOut,
        userDetails,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
