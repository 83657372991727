import { useEffect } from "react";

const EVENT_OPTIONS = { passive: true, capture: true };

const useDeviceTouchSimulator = (
  isEnabled: boolean,
  frame: HTMLIFrameElement | null
) => {
  const preventAllImagesDrag = (contentDocument: Document) => {
    const images = contentDocument.getElementsByTagName("img");

    for (const image of images) {
      image.setAttribute("draggable", "false");
    }

    return images;
  };

  useEffect(() => {
    if (!isEnabled || !frame || !frame.contentDocument) return;

    const images = preventAllImagesDrag(frame.contentDocument);

    let isTouching = false;
    let lastTouchPosition = { x: 0, y: 0 };

    const wheelEvent = (e: WheelEvent) => {
      e.preventDefault();
      frame.contentWindow?.scrollBy(e.deltaX, e.deltaY);
    };

    const mouseDownEvent = (e: MouseEvent) => {
      isTouching = true;
      lastTouchPosition = { x: e.clientX, y: e.clientY };
    };

    const mouseMouseMoveEvent = (e: MouseEvent) => {
      if (!isTouching) return;

      const deltaX = e.clientX - lastTouchPosition.x;
      const deltaY = e.clientY - lastTouchPosition.y;

      frame.contentWindow?.scrollBy(-deltaX, -deltaY);

      lastTouchPosition = { x: e.clientX, y: e.clientY };
    };

    const mouseUpEvent = () => {
      isTouching = false;
    };

    frame.contentDocument.addEventListener("wheel", wheelEvent, {
      passive: false,
    });

    frame.contentDocument.addEventListener(
      "mousedown",
      mouseDownEvent,
      EVENT_OPTIONS
    );
    frame.contentDocument.addEventListener(
      "mousemove",
      mouseMouseMoveEvent,
      EVENT_OPTIONS
    );
    frame.contentDocument.addEventListener(
      "mouseup",
      mouseUpEvent,
      EVENT_OPTIONS
    );

    const lastOverflow = frame.contentDocument.documentElement.style.overflow;
    frame.contentDocument.documentElement.style.overflow = "hidden";

    return () => {
      for (const image of images) {
        image.removeAttribute("draggable");
      }

      if (!frame.contentDocument) return;

      frame.contentDocument.documentElement.style.overflow = lastOverflow;
      frame.contentDocument.removeEventListener("wheel", wheelEvent);

      frame.contentDocument.removeEventListener(
        "mousedown",
        mouseDownEvent,
        EVENT_OPTIONS
      );
      frame.contentDocument.removeEventListener(
        "mousemove",
        mouseMouseMoveEvent,
        EVENT_OPTIONS
      );
      frame.contentDocument.removeEventListener(
        "mouseup",
        mouseUpEvent,
        EVENT_OPTIONS
      );
    };
  }, [frame, isEnabled]);
};

export default useDeviceTouchSimulator;
