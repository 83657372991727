import { Box, Modal } from "@mui/material";
import AddOrganizationForm from "./AddOrganizationForm";

interface AddOrganizationFormProps {
  isOnboardingModalOpen: boolean;
  handleClose: () => void;
}

const AddOrganizationModal: React.FC<AddOrganizationFormProps> = ({
  isOnboardingModalOpen,
  handleClose,
}) => {
  return (
    <Modal
      open={isOnboardingModalOpen}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      onClose={handleClose}
    >
      <Box>
        <AddOrganizationForm onClose={handleClose} />
      </Box>
    </Modal>
  );
};

export default AddOrganizationModal;
