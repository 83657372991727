import { AuthResponse } from "@supabase/supabase-js";
import { supabase } from "supabaseClient";

const checkForSessionTokenInUrl = (url: string) => {
  const urlParts = url.split("?");
  if (urlParts.length < 2) return null;

  const params = new URLSearchParams(urlParts[1]);

  const access_token = params.get("access_token");
  const refresh_token = params.get("refresh_token");

  if (!access_token || !refresh_token) return null;

  return {
    access_token,
    refresh_token,
  };
};

export const tryGetSessionFormUrl = async (): Promise<AuthResponse | null> => {
  const session = checkForSessionTokenInUrl(window.location.href);

  if (!session) {
    return null;
  }

  return await supabase.auth.setSession({ ...session });
};
