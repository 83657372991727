// credits to https://github.com/paarthenon/variant/blob/d4d5a479c1c668103b91e353416ad3fc395f1d37/src/variant.ts#L418

export type NullableUnionType<
  T extends { type: string },
  SelectedTypes extends T["type"] = T["type"],
> = {
  [P in SelectedTypes]: Extract<T, { type: P }> | undefined;
};

export type UnionType<
  T extends { type: string },
  SelectedTypes extends T["type"] = T["type"],
> = {
  [P in SelectedTypes]: Extract<T, { type: P }>;
};

export type UnionTypeArray<
  T extends { type: string },
  SelectedTypes extends T["type"] = T["type"],
> = {
  [P in SelectedTypes]: Extract<T, { type: P }>[];
};

export type Handler<T> = {
  [P in keyof T]: (variant: T[P]) => any;
};

export function match<
  T extends { type: string },
  H extends Handler<UnionType<T, SelectedTypes>>,
  SelectedTypes extends T["type"] = T["type"],
>(obj: T | undefined, handler: H): ReturnType<H[keyof H]> | null {
  if (obj === undefined) return null;
  return handler[obj.type as keyof H]?.(obj as any);
}

export type FilterByProperty<T, K extends keyof any> = T extends {
  [P in K]?: any;
}
  ? T
  : never;

export type NullableProps<T> = { [K in keyof T]: T[K] | null };
