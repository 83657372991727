import { useTheme } from "@mui/material";
import Popper from "@mui/material/Popper/Popper";
import { CheckAccessFrameContext } from "providers/CheckAccessFrame/CheckAccessFrame";
import React from "react";
import { useCallback, useContext, useMemo } from "react";

export enum DisplayedNodeType {
  Titles,
  Lists,
  Header,
  Footer,
  Main,
  Nav,
}

const TITLES_HEADINGS = ["H1", "H2", "H3", "H4", "H5", "H6"];

const BORDER_SIZE_PX = 6;

const NODE_TYPE_SELECTOR: Record<DisplayedNodeType, string> = {
  [DisplayedNodeType.Titles]: `${TITLES_HEADINGS.join(
    ", "
  )}, [role="heading"][aria-level]`,
  [DisplayedNodeType.Lists]: "ul, ol, dl, [role='list']",
  [DisplayedNodeType.Header]: "header",
  [DisplayedNodeType.Footer]: "footer",
  [DisplayedNodeType.Main]: "main",
  [DisplayedNodeType.Nav]: "nav",
};

interface Size {
  width: number;
  height: number;
}
const NODE_TYPE_ADDITIONAL_SIZE: Record<DisplayedNodeType, Size> = {
  [DisplayedNodeType.Titles]: { width: 24, height: 24 },
  [DisplayedNodeType.Lists]: { width: 24, height: 24 },

  [DisplayedNodeType.Header]: { width: -BORDER_SIZE_PX * 2, height: 0 },
  [DisplayedNodeType.Footer]: { width: -BORDER_SIZE_PX * 2, height: 0 },
  [DisplayedNodeType.Main]: { width: -BORDER_SIZE_PX * 2, height: 0 },
  [DisplayedNodeType.Nav]: { width: -BORDER_SIZE_PX * 2, height: 0 },
};

const getNodeDisplayTitle = (node: HTMLElement): string => {
  if (TITLES_HEADINGS.includes(node.tagName)) {
    return node.tagName;
  }

  if (
    node.getAttribute("role") === "heading" &&
    node.hasAttribute("aria-level")
  ) {
    return `H${node.getAttribute("aria-level")}`;
  }

  if (node.tagName === "UL") {
    return "Liste non ordonée";
  }

  if (node.tagName === "OL") {
    return "Liste ordonée";
  }

  if (node.tagName === "DL") {
    return "Liste de description";
  }

  if (node.getAttribute("role") === "list") {
    return "Liste";
  }

  return node.tagName;
};

const useInformationStructuringFrameRetribution = (
  displayedNodeType: DisplayedNodeType | null
): React.ReactElement[] => {
  const { palette } = useTheme();

  const { frame, shadowContainer } = useContext(CheckAccessFrameContext)!;
  const color = palette.warning.main;

  const getHighlightNodeElement = useCallback(
    (node: HTMLElement, index: number): React.ReactElement => {
      const rect = node.getBoundingClientRect();
      const additionalSize = NODE_TYPE_ADDITIONAL_SIZE[displayedNodeType!];

      return (
        <React.Fragment key={index}>
          <Popper
            open={true}
            anchorEl={node}
            container={() => shadowContainer.current}
            // pointerEvents in sx prop produces no style, so it is moved to the style prop
            style={{
              pointerEvents: "none",
              zIndex: 100000,
            }}
            modifiers={[
              { name: "flip", enabled: false },
              {
                name: "offset",
                options: {
                  offset: [
                    0,
                    -rect.height - BORDER_SIZE_PX - additionalSize.height / 2,
                  ],
                },
              },
            ]}
          >
            <div
              style={{
                width: rect.width + additionalSize.width,
                height: rect.height + additionalSize.height,
                border: `${BORDER_SIZE_PX}px solid ${color}`,
                backgroundColor: `rgba(${color}, 0.1)`,
                overflow: "hidden",
                borderRadius: "4px",
              }}
            >
              <div
                style={{
                  backgroundColor: color,
                  opacity: 0.2,
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </Popper>
          <Popper
            open={true}
            anchorEl={node}
            placement="top-start"
            container={() => shadowContainer.current}
            style={{ pointerEvents: "none", zIndex: 100001 }}
            modifiers={[
              { name: "flip", enabled: true },
              {
                name: "computeStyles",
                options: { adaptive: false },
              },
              {
                name: "offset",
                options: {
                  offset: [
                    -additionalSize.width / 2 - BORDER_SIZE_PX,
                    additionalSize.height / 2,
                  ],
                },
              },
            ]}
          >
            <div
              style={{
                pointerEvents: "none",
                backgroundColor: color,
                padding: "4px 12px 2px 12px",
                borderRadius: "4px",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              {getNodeDisplayTitle(node)}
            </div>
          </Popper>
        </React.Fragment>
      );
    },
    [color, displayedNodeType, shadowContainer]
  );

  return useMemo(() => {
    if (!frame?.contentDocument || displayedNodeType === null) return [];

    return Array.from(
      frame.contentDocument.querySelectorAll(
        NODE_TYPE_SELECTOR[displayedNodeType]
      )
    )
      .filter(
        (node): node is HTMLElement => node.nodeType === Node.ELEMENT_NODE
      )
      .map(getHighlightNodeElement);
  }, [getHighlightNodeElement, frame, displayedNodeType]);
};

export default useInformationStructuringFrameRetribution;
