import { useCallback, useRef, useState } from "react";
import {
  MetricFunctionName,
  MetricResult,
  TimeInterval,
  supabase,
} from "../supabaseClient";
import { logError } from "../utils/SentryUtils";

const computeCacheKeyTimeIntervalToString = (
  metric: string,
  timeInterval: TimeInterval
) => {
  return `${metric}-${timeInterval.dtStart.toISOString()}-${timeInterval.dtEnd.toISOString()}`;
};

const useMetricsCache = (
  currentTimeInterval: TimeInterval,
  domainId: string | null
) => {
  const [metricsCache, setMetricsCache] = useState<Map<string, any>>(new Map());
  const fetchingMetrics = useRef<string[]>([]);

  const fetchMetric = useCallback(
    async <T extends MetricFunctionName>(metric: T) => {
      const cacheKey = computeCacheKeyTimeIntervalToString(
        metric,
        currentTimeInterval
      );

      if (metricsCache.has(cacheKey)) return;

      if (!domainId) throw new Error("Domain ID is not set");

      if (fetchingMetrics.current.includes(cacheKey)) return;
      fetchingMetrics.current.push(cacheKey);

      const { data, error } = await supabase
        .schema("adaptify_analytics_metrics")
        .rpc(metric, {
          dt_start: currentTimeInterval.dtStart.toISOString(),
          dt_end: currentTimeInterval.dtEnd.toISOString(),
          domain_id: domainId,
        });

      if (error) logError("Error while fetching metric", error);

      if (data !== null)
        setMetricsCache((prev) => {
          const newCache = new Map(prev);
          newCache.set(cacheKey, data);
          return newCache;
        });
    },
    [domainId, fetchingMetrics, metricsCache, currentTimeInterval]
  );

  const getMetricResult = useCallback(
    <T extends MetricFunctionName>(metric: T): MetricResult<T> | null => {
      const cacheKey = computeCacheKeyTimeIntervalToString(
        metric,
        currentTimeInterval
      );
      return metricsCache.get(cacheKey);
    },
    [metricsCache, currentTimeInterval]
  );

  return { getMetricResult, fetchMetric };
};

export default useMetricsCache;
