import { useTheme } from "@mui/material";
import RetributionTooltip, {
  RetributionTooltipProps,
} from "./RetributionTooltip";
import { useEffect, useState } from "react";
import RetributionIcon from "./RetributionIcon";
import { useXPathFinder } from "providers/XPathFinder";

export interface RetributionButtonProps {
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  xPath: string;
  type: RetributionButtonType;
  retrivalId: string;

  tooltipProps: Omit<
    RetributionTooltipProps,
    "open" | "children" | "color" | "onClickAway"
  >;
}

export type RetributionButtonType = "error" | "warning" | "info";

const RetributionButton: React.FC<RetributionButtonProps> = ({
  Icon,
  tooltipProps,
  type,
  xPath,
  retrivalId,
}) => {
  const targetElement = useXPathFinder(xPath)!;
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isHoverIcon, setIsHoverIcon] = useState(false);
  const { palette } = useTheme();
  const color = palette[type].main;

  useEffect(() => {
    if (isTooltipOpen || isHoverIcon) {
      targetElement.style.outline = `${color} dashed 2px`;
    }

    return () => {
      targetElement.style.outline = "";
    };
  }, [color, isHoverIcon, isTooltipOpen, targetElement.style]);

  return (
    <RetributionTooltip
      color={color}
      open={isTooltipOpen}
      onClickAway={() => setIsTooltipOpen(false)}
      {...tooltipProps}
    >
      <RetributionIcon
        data-ca-retrival-id={retrivalId}
        Icon={Icon}
        color={color}
        onClick={() => setIsTooltipOpen(!isTooltipOpen)}
        onMouseEnter={() => setIsHoverIcon(true)}
        onMouseLeave={() => setIsHoverIcon(false)}
      />
    </RetributionTooltip>
  );
};

const ErrorRetributionButton: React.FC<Omit<RetributionButtonProps, "type">> = (
  props
) => <RetributionButton {...props} type="error" />;

const WarnRetributionButton: React.FC<Omit<RetributionButtonProps, "type">> = (
  props
) => <RetributionButton {...props} type="warning" />;

export default RetributionButton;
export { ErrorRetributionButton, WarnRetributionButton };
