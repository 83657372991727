import useCurrentProject from "hooks/useCurrentProject";
import { canUseAlphaFeatures } from "utils/envUtils";
import { SidebarCategory } from "./Sidebar";
import SidebarButton from "./SidebarButton";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import {
  createProjectRoute,
  ADAPTIFY_ANALYTICS_URL,
  ADAPTIFY_ANALYTICS_USAGE_URL,
  CHECK_ACCESS_URL,
} from "router";

const ProjectSidebar: React.FC = () => {
  const currentProject = useCurrentProject();

  const isAlphaFeaturesEnabled = canUseAlphaFeatures();

  return (
    <>
      <SidebarCategory title="Dashboards analytics">
        <SidebarButton
          Icon={DonutLargeIcon}
          link={createProjectRoute(currentProject.id, ADAPTIFY_ANALYTICS_URL)}
          text="Analytics"
          warning={!isAlphaFeaturesEnabled}
        />
        <SidebarButton
          Icon={DonutLargeIcon}
          link={createProjectRoute(
            currentProject.id,
            ADAPTIFY_ANALYTICS_USAGE_URL
          )}
          text="Analytics Adaptify"
          warning={!isAlphaFeaturesEnabled}
        />
      </SidebarCategory>
      <SidebarCategory title="Dashboards accessibilité">
        <SidebarButton
          Icon={DonutLargeIcon}
          link={createProjectRoute(currentProject.id, CHECK_ACCESS_URL)}
          text="Check Access"
          warning={!isAlphaFeaturesEnabled}
        />
      </SidebarCategory>
    </>
  );
};

export default ProjectSidebar;
