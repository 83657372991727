import { Intervention } from "@ca/report";
import React, { useCallback, useContext, useMemo } from "react";
import { InterventionsContext } from "providers/InterventionsProvider";
import { ReportContext } from "providers/Report";
import Box from "@mui/material/Box/Box";
import useScaner from "hooks/useScaner";
import FrameTitleIntervention from "../interventions/frames/FrameTitleIntervention";
import RetributionButtonPopper from "../RetributionButtonPopper";

export interface FramesRetributionProps {}

const FramesRetribution: React.FC<FramesRetributionProps> = () => {
  const { currentPageScan } = useScaner();

  const { replaceTargetedIntervention, getPageInfos } =
    useContext(InterventionsContext)!;
  const { pageReport, reportCurrentPage } = useContext(ReportContext)!;

  const handleUpdateIntervention = useCallback(
    (intervention: Intervention.frameTitle) => {
      if (!currentPageScan) return;
      replaceTargetedIntervention(intervention, currentPageScan.page_id);
      reportCurrentPage();
    },
    [replaceTargetedIntervention, currentPageScan, reportCurrentPage]
  );

  const isElementTooSmall = useCallback((el: HTMLElement) => {
    const rect = el.getBoundingClientRect();
    return rect.width < 80 || rect.height < 80;
  }, []);

  const framesRetribution = useMemo(() => {
    if (!currentPageScan) return [];

    return currentPageScan.scan.frames.map((frameScan) => {
      const intervention = getPageInfos(
        currentPageScan.page_id
      ).interventions.find(
        (it): it is Intervention.frameTitle =>
          it.type === "frameTitle" && it.x_path === frameScan.xPath
      );

      const needIntervention =
        pageReport?.neededInterventions.some(
          (intervention) =>
            "xPath" in intervention &&
            intervention.xPath === frameScan.xPath &&
            intervention.type === "frameTitle"
        ) || false;

      return (
        <RetributionButtonPopper
          key={frameScan.xPath}
          xPath={frameScan.xPath}
          placement={(el) =>
            isElementTooSmall(el) ? "bottom" : "bottom-start"
          }
          offset={(el) => (isElementTooSmall(el) ? [0, 4] : [4, -36])}
        >
          <Box
            sx={{
              display: "flex",
              gap: "4px",
            }}
          >
            <FrameTitleIntervention
              frameScan={frameScan}
              needIntervention={needIntervention}
              intervention={intervention || null}
              onUpdateIntervention={handleUpdateIntervention}
            />
          </Box>
        </RetributionButtonPopper>
      );
    });
  }, [
    currentPageScan,
    handleUpdateIntervention,
    getPageInfos,
    isElementTooSmall,
    pageReport?.neededInterventions,
  ]);

  return <React.Fragment>{framesRetribution}</React.Fragment>;
};

export default FramesRetribution;
