import { FrameScan, Intervention } from "@ca/report";
import React, { useContext, useMemo, useState } from "react";
import FormControl from "@mui/material/FormControl/FormControl";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import { Button, Typography } from "@mui/material";
import { ReactComponent as FrameIcon } from "../../../../../assets/icons/icon-frame.svg";
import { ReportContext } from "providers/Report";
import { match } from "utils/tsUtils";
import RetributionButton, {
  RetributionButtonProps,
  RetributionButtonType,
} from "../../RetributionButton";

export type FrameTitleInterventionProps = {
  frameScan: FrameScan;

  intervention: Intervention.frameTitle | null;

  needIntervention: boolean;

  onUpdateIntervention: (intervention: Intervention.frameTitle) => void;
};

const hasGoodTitle = (
  intervention: Intervention.frameTitle | null
): boolean | null => {
  return intervention?.has_good_title || null;
};

const FrameTitleIntervention: React.FC<FrameTitleInterventionProps> = ({
  frameScan,
  needIntervention,
  intervention,

  onUpdateIntervention,
}) => {
  const [isTitleCorrect, setIsTitleCorrect] = useState<boolean | null>(
    hasGoodTitle(intervention)
  );

  const { findErrorsByPathAndTypes } = useContext(ReportContext)!;

  const errors = useMemo(
    () =>
      findErrorsByPathAndTypes(frameScan.xPath, [
        "frameTitleNotRevelant",
        "noFrameTitle",
      ]),
    [findErrorsByPathAndTypes, frameScan.xPath]
  );

  const handleTitleCorrectChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsTitleCorrect((event.target as HTMLInputElement).value === "yes");
  };

  const handleSubmitInfo = () => {
    if (isTitleCorrect === null) return;

    onUpdateIntervention({
      type: "frameTitle",
      x_path: frameScan.xPath,
      has_good_title: isTitleCorrect,
    });
  };

  let radioIsTitleCorrect = null;
  if (isTitleCorrect) {
    radioIsTitleCorrect = "yes";
  } else if (isTitleCorrect === false) {
    radioIsTitleCorrect = "no";
  }

  const errorsMessages = useMemo(
    () =>
      Object.values(errors)
        .map((error) =>
          match(error, {
            frameTitleNotRevelant: () => "Le titre devrais décrire le cadre",
            noFrameTitle: () => "Le cadre ne possède pas de titre",
          })
        )
        .filter((error): error is string => error !== null),
    [errors]
  );

  const type: RetributionButtonType = needIntervention
    ? "warning"
    : errorsMessages.length > 0
      ? "error"
      : "info";

  let title = null;
  if (type === "warning") {
    title = "Nous avons besoin d'information à propos du titre du cadre.";
  } else if (errorsMessages.length > 1) {
    title = `${errorsMessages.length} erreurs ont été détectées sur ce cadre`;
  } else if (errorsMessages.length === 1) {
    title = errorsMessages[0];
  } else {
    title =
      "Tout est bon! Vous pouvez modifier les informations du cadre si vous le souhaitez";
  }

  const Icon: RetributionButtonProps["Icon"] = (props) => (
    <FrameIcon {...props} stroke="white" />
  );

  return (
    <RetributionButton
      retrivalId={`frameTitle-${frameScan.xPath}`}
      type={type}
      Icon={Icon}
      xPath={frameScan.xPath}
      tooltipProps={{
        title,
        content: (
          <FormControl component="fieldset" sx={{ width: "100%" }}>
            <Typography
              sx={{
                marginBottom: "0px",
                lineHeight: "1",
              }}
            >
              Le titre du cadre est-il correcte ?
            </Typography>
            <Typography variant="caption">"{frameScan.title}"</Typography>
            <RadioGroup
              value={radioIsTitleCorrect}
              onChange={handleTitleCorrectChange}
            >
              <FormControlLabel
                value={"yes"}
                sx={{
                  marginBottom: "-12px",
                  marginTop: "-2px",
                }}
                control={<Radio size="small" color={type} />}
                label="Le titre est correct"
              />
              <FormControlLabel
                value="no"
                control={<Radio size="small" color={type} />}
                label="Le titre n'est pas correct"
              />
            </RadioGroup>

            <Button
              variant="contained"
              sx={{
                mt: 2,
                borderRadius: "32px",
              }}
              color={type}
              onClick={handleSubmitInfo}
            >
              Valider
            </Button>
          </FormControl>
        ),
        referenceLink:
          "https://accessibilite.numerique.gouv.fr/methode/criteres-et-tests/#2",
      }}
    />
  );
};

export default FrameTitleIntervention;
