import { captureException } from "@sentry/react";

export const logError = (message: string, error?: any) => {
  console.error(message, error);
  captureException(error, {
    data: {
      message,
    },
  });
};
