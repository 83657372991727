import { ReactNode } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { Skeleton } from "@mui/material";

const MetricCard: React.FC<{
  children: ReactNode | null;
  Icon: ReactNode;
  title: string;
}> = ({ children, Icon, title }) => {
  return (
    <Card variant="outlined" sx={{ borderRadius: 4 }} className="flex flex-col">
      <CardHeader
        avatar={Icon}
        sx={{
          " .MuiCardHeader-title, .MuiSvgIcon-root": {
            color: "grey.700",
          },
          padding: 3,
        }}
        color="grey"
        title={title}
      />
      <CardContent sx={{ padding: 3, pt: 0 }}>
        {children || <Skeleton variant="rectangular" />}
      </CardContent>
    </Card>
  );
};

export default MetricCard;
