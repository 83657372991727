import { useEffect, useMemo } from "react";
import useOrganizations from "./useOrganizations";
import { useNavigate } from "react-router-dom";
import { Organization } from "../providers/OrganizationsProvider";
import { ORGANIZATIONS_URL } from "router";

// This is to avoid passing `Organization | null` to the rest of the app when waiting for redirect
const FAILOVER_ORGANIZATION: Organization = {
  created_at: "",
  id: "",
  name: "",
  owner_id: "",
  updated_at: "",
  members: [
    {
      user_id: "",
    },
  ],
  self: [
    {
      user_id: "",
    },
  ],
  projects: [
    {
      id: "",
      http_scheme: "http",
      name: "",
      domain: "",
      pages: [],
    },
  ],
};

const useOrganization = (organizationId: string): Organization => {
  const navigate = useNavigate();
  const organizations = useOrganizations();

  const organization = useMemo(
    () =>
      organizations
        .flatMap((organization) => organization)
        .find((organization) => organization.id === organizationId),
    [organizations, organizationId]
  );

  useEffect(() => {
    if (!organization) {
      navigate(ORGANIZATIONS_URL);
    }
  }, [organization, navigate]);

  return organization || FAILOVER_ORGANIZATION;
};

export default useOrganization;
