import React, { FC } from "react";
import Button from "@mui/material/Button/Button";
import { useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";

interface ErrorFallbackProps {
  error?: Error;
}

const ErrorFallback: FC<ErrorFallbackProps> = ({ error }) => {
  let errorPrepared = error;
  let routerError = useRouteError() as Error;

  if (!errorPrepared) {
    errorPrepared = routerError;
  }

  const resetErrorBoundary = () => {
    window.location.reload();
  };

  React.useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <div className="bg-primary min-h-screen flex justify-center items-center">
      <div className="flex flex-col items-center justify-center">
        <span className="flex text-foreground text-xs font-semibold mb-6 tracking-tight">
          APP CRASHED
        </span>
        <h4 className="font-semibold text-foreground text-lg">
          Oops, a critical error happened...
        </h4>
        <div className="max-w-xs text-xs text-foreground leading-6 mt-5 mb-6 font-light font-mono">
          Error: {errorPrepared.message}
        </div>
        <div className="flex flex-row">
          <Button
            onClick={resetErrorBoundary}
            sx={{
              backgroundColor: "secondary.main",
              color: "primary.main",
              "&:hover": {
                backgroundColor: "secondary.dark",
              },
            }}
          >
            Reload
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
