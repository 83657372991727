import Box from "@mui/material/Box/Box";
import React, { useContext } from "react";
import {
  getCriteriaState,
  NonCompliantCriteria,
  ReportContext,
  TargetedNonCompliantErrors,
} from "providers/Report";
import SidebarTopicHeader from "./SidebarTopicHeader";
import { ReactComponent as ContrastIcon } from "../../../../assets/icons/icon-contrast.svg";
import AccordionPictoLink from "../accordion/AccordionPictoLink";

export interface MandatoryElementsSidebarTopicDetailsProps {}

const ColorsSidebarTopicDetails: React.FC<
  MandatoryElementsSidebarTopicDetailsProps
> = () => {
  const { pageReport } = useContext(ReportContext)!;

  const criteria = pageReport?.criterias.find(
    (criteria) => criteria.topic === "colors" && criteria.number === 2
  );

  if (!criteria) return null;

  const criteriaState = getCriteriaState(criteria);

  const errors =
    criteriaState === "nonCompliant"
      ? (criteria as NonCompliantCriteria).state.nonCompliant
      : [];

  return (
    <Box sx={{ width: "100%" }}>
      <SidebarTopicHeader topic="colors" titleFontSize="1.5rem" />
      <Box
        sx={{
          margin: "25px 8px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box>
          <AccordionPictoLink
            title="3.2 Contraste du texte"
            IconError={(props) => (
              <ContrastIcon {...props} fill="white" stroke="white" />
            )}
            xPathsError={errors.map(
              (error) => (error as TargetedNonCompliantErrors).x_path
            )}
            retrivalKey="textContrast"
            topic="colors"
            criteriaNumber={2}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ColorsSidebarTopicDetails;
